<template>
    <div id="default-layout" class="flex min-h-screen">
        <template v-if="appStore.isSidebarAllowed">
            <AppSidebarMobile class="z-20" />
            <AppSidebarDesktop class="z-20" />
        </template>

        <div
            class="flex w-full flex-1 flex-col"
            :class="{
                'lg:pl-64':
                    appStore.isSidebarAllowed && appStore.desktopSidebarOpen,
            }"
        >
            <header class="sticky top-0 z-10">
                <slot name="header">
                    <AppHeader />
                </slot>
            </header>

            <main class="relative px-4 pb-24 pt-8 md:px-6 xl:pt-10">
                <div class="relative mx-auto max-w-7xl">
                    <!-- Prepend -->
                    <slot name="prepend" />

                    <div
                        class="flex flex-col gap-x-4 gap-y-8 lg:flex-row-reverse"
                    >
                        <!-- Page content -->
                        <div class="min-w-0 flex-1">
                            <slot />
                        </div>
                    </div>
                </div>
            </main>
            <!-- <AppFooter /> -->
        </div>
    </div>
</template>

<script setup lang="ts">
const appStore = useAppStore()
</script>
