<template>
    <div
        v-if="appStore.isSidebarAllowed && appStore.desktopSidebarOpen"
        class="hidden lg:fixed lg:inset-y-0 lg:flex lg:w-64"
    >
        <div class="flex min-h-0 flex-1 flex-col">
            <div
                class="flex h-[var(--nav-height)] items-center justify-center bg-gray-900"
            >
                <AppSidebarCompanySelect class="w-56" />
            </div>
            <div
                class="flex flex-1 flex-col items-center overflow-y-auto bg-gray-800"
            >
                <AppSidebarMenuList class="w-full flex-1 px-2 py-4" />
            </div>
        </div>
    </div>
</template>

<script setup lang="ts">
const appStore = useAppStore()

watchEffect(() => {
    appStore.desktopSidebarOpen =
        breakpoint.lg.value && appStore.sidebarStorageState
})
</script>
