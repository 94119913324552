<template>
    <BaseSelect
        :model-value="authStore.selectedCompany"
        :options="authStore.user?.companies"
        :option-text="
            ({ name_bg, name_en }) =>
                locale === Locale.EN && name_en ? name_en : name_bg
        "
        option-value="id"
        dark
        mask-on-open
        @update:model-value="onSelectCompany"
    >
        <template
            #trigger-button-selected="{
                selected,
            }: {
                selected: CompanyResponse
            }"
        >
            <div class="flex items-center overflow-hidden">
                <div v-if="selected.logo" class="h-5 w-5 shrink-0">
                    <img
                        :src="selected.logo.url"
                        :alt="`${selected.name_bg} company logo`"
                        class="h-full w-full object-contain"
                        width="20"
                        height="20"
                    />
                </div>

                <span class="text-md ml-2 truncate font-medium">
                    {{
                        locale === Locale.EN && selected.name_en
                            ? selected.name_en
                            : selected.name_bg
                    }}
                </span>
            </div>
        </template>

        <template #list-append>
            <BaseButton
                :is="NuxtLink"
                to="/companies"
                color="info"
                variant="text"
                class="w-full"
            >
                <Cog6ToothIcon class="mr-1.5 h-5 w-5 shrink-0" />
                {{ t('manage-companies') }}
            </BaseButton>
        </template>
    </BaseSelect>
</template>

<script setup lang="ts">
import { Cog6ToothIcon } from '@heroicons/vue/24/solid'
import { NuxtLink } from '#components'
import type { CompanyResponse } from '~/schemas'
import { Locale } from '@prisma/client'

const authStore = useAuthStore()
const { t, locale } = useI18n()

async function onSelectCompany(companyId: string) {
    try {
        await $api.me.selectCompany(companyId)
        await authStore.fetchAuthUser()
        await navigateTo(companyPath('/'))
    } catch (error) {
        console.error(error)
    }
}
</script>
