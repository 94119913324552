<template>
    <TransitionRoot
        as="template"
        :show="appStore.isSidebarAllowed && appStore.mobileSidebarOpen"
    >
        <Dialog
            as="div"
            class="relative z-10 lg:hidden"
            @close="appStore.mobileSidebarOpen = false"
        >
            <TransitionChild
                as="template"
                enter="transition-opacity ease-linear duration-300"
                enter-from="opacity-0"
                enter-to="opacity-100"
                leave="transition-opacity ease-linear duration-300"
                leave-from="opacity-100"
                leave-to="opacity-0"
            >
                <div class="fixed inset-0 bg-gray-600 bg-opacity-75" />
            </TransitionChild>

            <div class="fixed inset-0 z-10 flex">
                <TransitionChild
                    as="template"
                    enter="transition ease-in-out duration-300 transform"
                    enter-from="-translate-x-full"
                    enter-to="translate-x-0"
                    leave="transition ease-in-out duration-300 transform"
                    leave-from="translate-x-0"
                    leave-to="-translate-x-full"
                >
                    <DialogPanel
                        class="relative flex w-72 flex-col bg-gray-800 pb-4 pt-5"
                    >
                        <TransitionChild
                            as="template"
                            enter="ease-in-out duration-300"
                            enter-from="opacity-0"
                            enter-to="opacity-100"
                            leave="ease-in-out duration-300"
                            leave-from="opacity-100"
                            leave-to="opacity-0"
                        >
                            <div class="absolute right-0 top-0 -mr-12 pt-2">
                                <button
                                    type="button"
                                    class="ml-1 flex h-10 w-10 items-center justify-center rounded-full"
                                    @click="appStore.mobileSidebarOpen = false"
                                >
                                    <span class="sr-only">
                                        {{ t('close-sidebar') }}
                                    </span>
                                    <XMarkIcon
                                        class="h-6 w-6 text-white"
                                        aria-hidden="true"
                                    />
                                </button>
                            </div>
                        </TransitionChild>
                        <div
                            class="flex flex-shrink-0 items-center justify-center"
                        >
                            <AppSidebarCompanySelect class="w-64" />
                        </div>
                        <div class="mt-5 h-0 flex-1 overflow-y-auto">
                            <AppSidebarMenuList class="px-2" />
                        </div>
                    </DialogPanel>
                </TransitionChild>
                <div class="w-14 flex-shrink-0" aria-hidden="true">
                    <!-- Dummy element to force sidebar to shrink to fit close icon -->
                </div>
            </div>
        </Dialog>
    </TransitionRoot>
</template>

<script setup lang="ts">
import {
    Dialog,
    DialogPanel,
    TransitionChild,
    TransitionRoot,
} from '@headlessui/vue'
import { XMarkIcon } from '@heroicons/vue/24/solid'

const appStore = useAppStore()
const route = useRoute()
const { t } = useI18n()

watch(
    () => route.path,
    () => (appStore.mobileSidebarOpen = false),
)
</script>
